<template>
  <div :style="pagePaddingTop">
    <div class="pc head-container">
      <div class="container container-wrap">
        <div class="container-title unselect subtitle5"
             :class="{'rightLine': index !== items.length - 1}"
             v-for="(item, index) in items"
             :key="'item-'+index"
             @click="clickMenu(item)">

          <div>
            <div class="flex-center" style="height:36px">
              <svg-icon :icon="item.path.replace('/','')" :color="getColor(item.path)"></svg-icon>
            </div>
            <div :class="getColor(item.path)" class="margin-top-8">{{item.name}}</div>
          </div>
        </div>
      </div>
    </div>
    <router-view class="router-view"></router-view>

  </div>

</template>
<script>

  import SvgIcon from "../component/SvgIcon";
  export default {
    name:"MyPages",
    components: {SvgIcon},
    data(){
      return {
        items:[
          {
            name:'내 서비스',
            path:'my_service',
            icon: 'draw_service'
          },
          {
            name:'맞춤제작 신청 내역',
            path:'inquiry',
            icon: 'Prime'
          },
          {
            name:'API 신청 내역',
            path:'api',
            icon: 'API'
          },
          {
            name:'소스 파일 다운로드',
            path:'my_templates',
            icon: 'draw_api'
          },
          {
            name:'소스 파일 등록 문의내역',
            path:'my_template_inquiry',
            icon: 'fi_message-square'
          },
          {
            name:'내 정보 수정',
            path:'profile',
            icon: 'draw_edit'
          },
        ]
      }
    },
    methods: {
      getColor(path) {
        return this.$route.fullPath.replace('/mypage/','') === path ? 'primary' : 'sub2';
      },
      clickMenu(item) {
        this.setGa(`마이페이지 탭 (${item.name})`, '클릭', item.name);
        this.$router.push('/mypage/' + item.path);
      },
      cellStyle(text) {
        let val = 0;
        if (text.length < 3) val = 62;
        else if (text.length < 5) val = 92;
        else if (text.length < 7) val = 100;
        else if (text.length < 9) val = 130;
        else if (text.length < 11) val = 160;
        else if (text.length < 13) val = 180;
        else if (text.length < 20) val = 200;
        else val = 260;
        return {width: `${val}px`};
      },
      icon(img) {
        return `/static/icon/${img.replace(':','_')}.svg`;
      },
    },
    computed: {
      pagePaddingTop() {
        let deco = {};
        let isMobile = matchMedia("(max-width: 1024px)").matches;
        if (!isMobile) {
          deco.paddingTop = '64px'
        }
        return deco;
      },
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'

  .head-container
    width 100%
    height auto
    background-color white
    border-bottom 1px solid $gray1
    z-index 9

  .container-wrap
    display grid
    grid-template-columns repeat(6, 1fr)
    padding 32px 0

  .container-title
    text-align center
    cursor pointer

  .container-title:hover
    color $primary !important

  .rightLine
    border-right 1px solid $gray2

  .bottomLine
    border-bottom 3px solid #ff6600
    height auto
    top -2px


  .primary
    color $primary

  .router-view
    background-color $gray3
    padding-bottom 120px
    min-height calc(100vh - 64px)

  .icon-menu
    width 24px


  @media(max-width:1024px)
    .router-view
      padding-top 60px
      padding-bottom 60px
</style>
